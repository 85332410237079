import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AnnotatedMetadata, MetaEntityType } from "src/model/metaEntity";
import { BreezeValidatorsService } from "src/app/services/breeze-validators/breeze-validators.service";
import DefaultBreezeValidators from "src/app/services/breeze-validators/default-breeze-validators";
import * as Config from "src/config";
import Utilities from "src/app/services/utilities";
import * as breeze from "breeze-client";
import { enableSaveQueuing } from "breeze-client/mixin-save-queuing";
import { DataServiceWebApiAdapter } from "breeze-client/adapter-data-service-webapi";
import { ModelLibraryBackingStoreAdapter } from "breeze-client/adapter-model-library-backing-store";
import { UriBuilderJsonAdapter } from "breeze-client/adapter-uri-builder-json";
import { AjaxHttpClientAdapter } from "breeze-client/adapter-ajax-httpclient";
import invariant from "tiny-invariant";

@Injectable({
  providedIn: "root",
})
export class EntityManagerProviderService {
  private initialized: boolean = false;

  rights = {
    name: "rights",
    isUnmapped: true,
    nameOnServer: "Rights",
    dataType: breeze.DataType.Undefined,
  };

  private serviceName: string = Config.serviceName;
  private masterManager: breeze.EntityManager = new breeze.EntityManager({
    serviceName: this.serviceName,
    saveOptions: new breeze.SaveOptions({
      allowConcurrentSaves: true,
    }),
  });

  private _manager: breeze.EntityManager | undefined;

  private cachedMetadataHash: string | undefined;
  private cachedAnnotatedMetadataHash: string | undefined;
  private cachedLookupsHash: string | undefined;

  constructor(
    private http: HttpClient,
    private breezeValidators: BreezeValidatorsService,
  ) {
    // the order is important
    ModelLibraryBackingStoreAdapter.register();
    UriBuilderJsonAdapter.register();
    AjaxHttpClientAdapter.register(this.http);
    DataServiceWebApiAdapter.register();
  }

  private prepare() {
    this.cachedMetadataHash =
      localStorage.getItem(Config.stateKeys.metadataHash) ?? undefined;
    this.cachedAnnotatedMetadataHash =
      localStorage.getItem(Config.stateKeys.annotatedMetadataHash) ?? undefined;
    this.cachedLookupsHash =
      localStorage.getItem(Config.stateKeys.lookupsHash) ?? undefined;

    return this.hydrateMetadata().then(() =>
      Promise.all([this.hydrateLookups(), this.hydrateAnnotatedMetadata()]),
    );
  }

  private hydrateMetadata(): Promise<boolean> {
    const metadata = localStorage.getItem(Config.stateKeys.breezeMetadata);

    // if (
    //   !this.cachedMetadataHash ||
    //   !metadata ||
    //   this.cachedMetadataHash !== Config.hashes.currentMetadataHash
    // ) {
    //   return this.masterManager.fetchMetadata().then(() => {
    //     this.cacheMetadata();
    //     return true;
    //   });
    // }

    // this.masterManager.metadataStore =
    //   breeze.MetadataStore.importMetadata(metadata);
    return Utilities.valueAsPromise(true);
  }

  private cacheMetadata() {
    const meta = this.masterManager.metadataStore.exportMetadata();
    localStorage.setItem(Config.stateKeys.breezeMetadata, meta);
    localStorage.setItem(
      Config.stateKeys.metadataHash,
      Config.hashes.currentMetadataHash,
    );
  }

  private hydrateLookups(): Promise<boolean> {
    const lookups = localStorage.getItem(Config.stateKeys.lookups);

    if (
      !this.cachedLookupsHash ||
      !lookups ||
      this.cachedLookupsHash !== Config.hashes.currentLookupsHash
    ) {
      const query = breeze.EntityQuery.from("lookups");

      return this.masterManager.executeQuery(query).then(() => {
        this.cacheLookups();
        return true;
      });
    }

    this.masterManager.importEntities(lookups);
    return Utilities.valueAsPromise(true);
  }

  private cacheLookups() {
    const lookups = this.masterManager.exportEntities();
    localStorage.setItem(Config.stateKeys.lookups, `${lookups}`);
    localStorage.setItem(
      Config.stateKeys.lookupsHash,
      Config.hashes.currentLookupsHash,
    );
  }

  private hydrateAnnotatedMetadata(): Promise<boolean> {
    const annotated = localStorage.getItem(Config.stateKeys.annotatedMetadata);

    if (
      !this.cachedAnnotatedMetadataHash ||
      !annotated ||
      this.cachedAnnotatedMetadataHash !==
        Config.hashes.currentAnnotatedMetadataHash
    ) {
      return this.http
        .get<AnnotatedMetadata[]>(this.serviceName + "/annotatedmetadata")
        .toPromise()
        .then((data) => {
          invariant(data !== undefined);
          this.parseAnnotatedMetadata(data);
          this.cacheAnnotatedMetadata(JSON.stringify(data));
          return true;
        });
    }

    this.parseAnnotatedMetadata(<AnnotatedMetadata[]>JSON.parse(annotated));
    return Utilities.valueAsPromise(true);
  }

  private cacheAnnotatedMetadata(data: string) {
    localStorage.setItem(Config.stateKeys.annotatedMetadata, data);
    localStorage.setItem(
      Config.stateKeys.annotatedMetadataHash,
      Config.hashes.currentAnnotatedMetadataHash,
    );
  }

  private parseAnnotatedMetadata(data: AnnotatedMetadata[]) {
    const entityManager = this.masterManager;
    const metadataStore = entityManager.metadataStore;

    data.forEach((metaEntity: AnnotatedMetadata) => {
      const entityType = <MetaEntityType>(
        metadataStore.getEntityType(metaEntity.key, true)
      );

      if (entityType) {
        // eslint-disable-next-line
        (metadataStore as any)[entityType.shortName] = function () {
          return entityType;
        };

        entityType.displayName = metaEntity.value.meta.displayName;
        entityType.meta = metaEntity.value.meta;
        entityType.props = {};

        const entityProps = entityType.getProperties();
        entityProps.forEach((entityProp) => {
          // eslint-disable-next-line
          (entityType as any).props[entityProp.name] = function () {
            return entityProp;
          };

          const metaProp =
            // eslint-disable-next-line
            metaEntity.value.properties[(entityProp as any).name];

          if (metaProp) {
            // eslint-disable-next-line
            entityProp.displayName = (metaProp as any).displayName;
            // eslint-disable-next-line
            (entityProp as any).meta = metaProp;

            DefaultBreezeValidators.setupEFValidators(
              entityProp.validators,
              // eslint-disable-next-line
              (entityProp as any).meta,
            );
          }
        }, this);
      }
    });

    this.breezeValidators.setupCustomValidators(data, entityManager);
  }

  private buildManager(): void {
    this._manager = this.masterManager.createEmptyCopy();
    this._manager.saveOptions.allowConcurrentSaves = true;

    enableSaveQueuing(this._manager);

    // Populate with lookup data
    this._manager.importEntities(this.masterManager.exportEntities());
  }

  manager(): breeze.EntityManager {
    if (!this._manager) {
      this.buildManager();
      invariant(this._manager);
    }

    return this._manager;
  }

  init(): Promise<boolean> {
    if (this.initialized) {
      return Utilities.valueAsPromise(this.initialized);
    }

    enableSaveQueuing(this.masterManager);

    return this.prepare()
      .then(() => {
        this.buildManager();
        this.initialized = true;
        return true;
      })
      .catch((error) => {
        console.log(error);

        return Promise.reject(error);
      });
  }
}
