export type AssignmentStatus = 
	| "NotStarted"
	| "InProgress"
	| "Complete"
	| "Cancelled"
export const AssignmentStatus_NotStarted = "NotStarted";
export const AssignmentStatus_InProgress = "InProgress";
export const AssignmentStatus_Complete = "Complete";
export const AssignmentStatus_Cancelled = "Cancelled";
export type ProjectStatus = 
	| "Planning"
	| "Started"
	| "InProgress"
	| "Complete"
	| "Cancelled"
export const ProjectStatus_Planning = "Planning";
export const ProjectStatus_Started = "Started";
export const ProjectStatus_InProgress = "InProgress";
export const ProjectStatus_Complete = "Complete";
export const ProjectStatus_Cancelled = "Cancelled";
export type AppContentType = 
	| "PolicyHeader"
	| "Policy"
	| "TermsAndConditionsHeader"
	| "TermsAndConditions"
	| "AutoDetect"
	| "English"
	| "Spanish"
	| "French"
	| "Arabic"
	| "Hindi"
	| "Italian"
	| "German"
	| "Portuguese"
	| "DocumentSearch"
export const AppContentType_PolicyHeader = "PolicyHeader";
export const AppContentType_Policy = "Policy";
export const AppContentType_TermsAndConditionsHeader = "TermsAndConditionsHeader";
export const AppContentType_TermsAndConditions = "TermsAndConditions";
export const AppContentType_AutoDetect = "AutoDetect";
export const AppContentType_English = "English";
export const AppContentType_Spanish = "Spanish";
export const AppContentType_French = "French";
export const AppContentType_Arabic = "Arabic";
export const AppContentType_Hindi = "Hindi";
export const AppContentType_Italian = "Italian";
export const AppContentType_German = "German";
export const AppContentType_Portuguese = "Portuguese";
export const AppContentType_DocumentSearch = "DocumentSearch";
export type SenderType = 
	| "User"
	| "Assistant"
	| "Error"
export const SenderType_User = "User";
export const SenderType_Assistant = "Assistant";
export const SenderType_Error = "Error";
export type AppUploadedFileFileTypeCodes = 
	| "Logo"
	| "Favicon"
	| "ChatSplashImage"
	| "ChatMessageImage"
export const AppUploadedFileFileTypeCodes_Logo = "Logo";
export const AppUploadedFileFileTypeCodes_Favicon = "Favicon";
export const AppUploadedFileFileTypeCodes_ChatSplashImage = "ChatSplashImage";
export const AppUploadedFileFileTypeCodes_ChatMessageImage = "ChatMessageImage";
export type AppUploadedFileEntityTypeCodes = 
	| "AdminSettings"
	| "Messages"
export const AppUploadedFileEntityTypeCodes_AdminSettings = "AdminSettings";
export const AppUploadedFileEntityTypeCodes_Messages = "Messages";
