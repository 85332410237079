import { Injectable } from "@angular/core";
import * as Config from "src/config";
import { ServerSidePublicConfigData } from "src/model/modelDto";
import invariant from "tiny-invariant";
import { setSiteTheme } from "../unit-of-work/unit-of-work.service";

@Injectable({
  providedIn: "root",
})
export class ServerSidePublicConfigDataService {
  configData: ServerSidePublicConfigData | undefined;

  async loadServerSidePublicData(): Promise<boolean> {
    if (!this.configData) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        // Use fetch directly instead of httpClient to avoid interceptors
        const response = await fetch(
          `${Config.serviceName}/getServerSidePublicConfigData`,
          requestOptions,
        );
        const result = (await response.json()) as
          | ServerSidePublicConfigData
          | null
          | undefined;
        invariant(result);
        this.configData = result;

        invariant(result.adTenantId, "No AdTenantId configured");
        invariant(result.adClientId, "No AdClientId configured");
        invariant(result.appInsightsKey, "No AppInsightsKey configured");

        window.__settings = {
          environment: "",
          appName: result.appName ?? "",
          companyName: result.companyName ?? "",
          preferredColor: result.preferredColor ?? "",

          adTenantId: result.adTenantId,
          adClientId: result.adClientId,

          appInsightsKey: result.appInsightsKey,

          logoWidth: result.logoWidth ?? undefined,
          logoHeight: result.logoHeight ?? undefined,
        };

        setSiteTheme(result.preferredColor ?? "");
        return true;
      } catch (err) {
        return Promise.reject(err);
      }
    }

    return true;
  }
}
