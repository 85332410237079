import { EntityManager, EntityQuery } from "breeze-client";
import {
  BreezeOptions,
  instanceOfInlineCountQueryOptions,
} from "src/app/services/utilities";

// TODO: this is harder than it looks
export type OrderByString<TData> = string;

// TODO: this is harder than it looks
export type FieldString<TData> = Extract<keyof TData, string>;

export interface BaseGetManyRequest<TData> {
  pageSize: number;
  pageNumber: number;
  orderBy: OrderByString<TData>;
}

function extractJsonFromQueryString(
  resourceName: string,
  queryString: string,
): string | null {
  const prefix = `${resourceName}?`;
  if (!queryString.startsWith(prefix)) {
    return null;
  }

  const encodedJson = queryString.substring(prefix.length);
  const decodedJson = decodeURIComponent(encodedJson);
  return decodedJson;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function getBreezeQueryString<O extends BreezeOptions>(
  em: EntityManager,
  resource: string,
  options: O,
) {
  const { sort, filter } = options;
  const query = augmentBreezeQuery(new EntityQuery().from(resource), {
    sort,
    filter,
  });

  const url = query._toUri(em);
  const parsed = extractJsonFromQueryString(resource, url);
  return parsed;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function augmentBreezeQuery<O extends BreezeOptions>(
  query: EntityQuery,
  options: O | undefined,
) {
  if (options) {
    const inlineCount = instanceOfInlineCountQueryOptions(options)
      ? options.inlineCount
      : false;

    if (options.filter) {
      query = query.where(options.filter);
    }

    if (options.sort) {
      query = query.orderBy(options.sort);
    } else {
      if (inlineCount && typeof options.take === "number" && options.take > 0) {
        throw Error(
          "Oops! Inline count won't work unless you provide a 'sort' column or take 0.",
        );
      }
    }

    // NOTE: For security, we're disallowing the .include functionality
    //if (Array.isArray(options.include) && options.include.length) {
    //    query = query.expand(options.include);
    //}

    if (inlineCount) {
      query = query.inlineCount(inlineCount);
    }

    if (options.noTracking) {
      query = query.noTracking(options.noTracking);
    }

    // Disabling because the TypeScript to get this correct is hard
    //if (Array.isArray(options.select) && options.select.length) {
    //    query = query.select(options.select);
    //}

    if (typeof options.skip === "number" && options.skip >= 0) {
      query = query.skip(options.skip);
    }

    if (typeof options.take === "number" && options.take >= 0) {
      query = query.take(options.take);
    }

    if (typeof options.top === "number" && options.top >= 0) {
      query = query.top(options.top);
    }
  }

  return query;
}
